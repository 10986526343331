<template>
    <div class="data-table" v-bind:class="{compact: compact}">
        <div class="header" v-bind:class="{hidden: hideHeader}" v-bind:style="[headerStyles]" style="top: 0; z-index: 10; background: var(--bg);">
            <div class="entry" v-for="(column, indexH) in header" :key="column.label + indexH"
                 v-bind:style="[{width: column.width, flex: column.width && column.width.indexOf('px') >= 0 && column.width.indexOf('calc') === -1 ? 'none' : '1 1 auto', 'margin-left': column.map.marginleft, 'margin-right': column.map.marginRight, background: column.map.bgcolor && coloredHeader? column.map.bgcolor : '' }, GetHeaderStyles(column)]"
                 v-on:click="column.map.action ? column.map.action($event, column.map.data) : Sort(column)"
                 v-tooltip="GetTooltip(indexH)"
                 v-bind:class="[{sortable: column.map.sort || column.map.action, 'no-border' : column.map.noBorder, 'align-right': column.map.alignRight, 'align-left': column.map.alignLeft, 'label-small': column.map.labelSmall, 'label-no-break': column.map.labelNoBreak, collapsed: column.map.collapsed}, column.map.alignHeaderLabel, column.map.classes]">
                <component class="component" v-if="column.componentTypeColumn" v-bind:is="column.componentTypeColumn" v-bind="column.map.componentPropContainer" :state="column.componentState"
                       v-on="column.map.customEvents"/>
                <div class="label" v-if="!column.map.icon" v-bind:style="[headerStyles]">{{column.label}} <div v-if="column.map.labelicon" class="icon" v-bind:class="[column.map.labelicon]"></div></div>
                <div class="icon" @click="ToggleCollapsedState(column.map)" v-if="column.map.icon && !column.map.collapsed" v-bind:class="[column.map.icon]" v-bind:style="[headerStyles]"></div>
                <div class="fas fa-sort" v-if="!column.componentTypeColumn && column.map.sort && sort.column.label !== column.label"></div>
                <div class="fas fa-angle-down" v-if="!column.componentTypeColumn && column.map.sort && sort.column.label === column.label && sort.descending"></div>
                <div class="fas fa-angle-up" v-if="!column.componentTypeColumn && column.map.sort && sort.column.label === column.label && !sort.descending"></div>
                <dropdownsearch v-if="!column.componentTypeColumn && column.map.dropdown" :state="column.map.dropdown"></dropdownsearch>
                <div v-if="!column.componentTypeColumn && column.map.button" v-on:click="column.map.button.action($event, column.map.data)" v-bind:class="[column.map.button.icon, !column.map.button.icon ? 'button pill' : '']">
                    <div class="label">{{column.map.button.label}}</div>
                </div>
            </div>
        </div>
        <div v-bind:id="'draggable' + draggableId">
            <div class="row" v-for="(row, indexR) in rows" :key="indexR + JSON.stringify(row)" v-bind:class="{collapsed: row.subEntries && collapsedStates[indexR]}" v-bind:style="[{height: row.subEntries && !collapsedStates[indexR] ? heightSubRow === 'fit-content' ? 'fit-content' : (heightRow + ((heightSubRow ? heightSubRow : heightRow) * (row.subEntries.length))) + 'px' : heightRow + 'px', minHeight: heightRow + 'px'}, GetRowStyles(row)]">
                <div class="row-container" v-on:click="RowSelect(row)" v-bind:class="{selected: rowSelect && rowsSelected.find(r => r === row)}">
                    <div class="hover"></div>
                    <div class="entry" v-for="(entry, indexE) in row.entries" :key="indexE" v-bind:style="[{background: entry.OnChange && !entry.disabled ? entry.map.OnChangeColor : (entry.map.bgcolor ? entry.map.bgcolor : ''), width: GetEntryWidth(entry), 'max-width': entry.map.collapsed || GetEntryWidth(entry).substr(-2) === 'px' ? GetEntryWidth(entry) : '', flex: entry.width && entry.width.indexOf('px') >= 0 && entry.width.indexOf('calc') === -1 ? 'none' : '1 1 auto', height: heightRow + 'px', 'margin-left': entry.map.marginleft, 'margin-right': entry.map.marginRight}, GetEntryStyles(entry, row.data, row)]"
                        v-bind:class="[{editing: inputFocusState[indexR + '/' + indexE], editable: entry.OnChange, 'edit-disabled': entry.disabled, divider: entry.map.divider, collapsed: entry.map.collapsed}, entry.align, {'align-right': entry.map.alignRight}]">
                        <component v-for="(component, indexC) in entry.components" v-bind:is="component.componentType" :key="indexC" class="component" v-bind="component" v-on="component.customEvents" @click.native="component.click ? component.click : () => {}"/>
                        <div class="divider"></div>
                    </div>
                    <div class="fas" v-if="row.subEntries" v-bind:class="{'fa-angle-down': collapsedStates[indexR], 'fa-angle-up': !collapsedStates[indexR], disabled: row.subEntries.length === 0}" v-on:click="CollapseRow(indexR)"></div>
                    <div v-if="draggable" class="fas fa-up-down" style="position: relative; margin: auto; bottom: 5px"></div>
                </div>
                <div class="row-container sub" v-on:click="RowSelect(subRow)" v-if="row.subEntries" v-for="(subRow, indexR) in row.subEntries" :key="indexR" v-bind:class="{collapsed: row.subEntries && row.collapsed, selected: rowSelect && rowsSelected.find(r => r === row)}" v-bind:style="[{height: heightSubRow === 'fit-content' ? 'fit-content' : ((heightSubRow  ? heightSubRow : heightRow) + 'px')}, GetRowStyles(subRow)]">
                    <div class="flex-divider"></div>
                    <div class="hover"></div>
                    <div class="row-container" v-bind:style="{height: (heightSubRow ? heightSubRow : heightRow) + 'px'}">
                        <div class="entry" v-for="(entry, indexE) in subRow.entries" :key="indexE" v-bind:style="[{background: entry.OnChange && !entry.disabled ? entry.map.OnChangeColor : '', width: GetEntryWidth(entry), 'max-width': entry.map.collapsed || GetEntryWidth(entry).substr(-2) === 'px' ? GetEntryWidth(entry) : '', flex: entry.width && entry.width.indexOf('px') >= 0 && entry.width.indexOf('calc') === -1 ? 'none' : '1 1 auto', height: heightSubRow === 'fit-content' ? 'fit-content' : (heightSubRow ? heightSubRow : heightRow) + 'px', 'margin-left': entry.map.marginleft, 'margin-right': entry.map.marginRight}, GetEntryStyles(entry, subRow.data, subRow), {padding: subRow.data.componentOverride ? '0' : ''}]" v-bind:class="[{editable: entry.OnChange}, entry.align, {'align-right': entry.map.alignRight}]">
                            <component v-for="(component, indexC) in entry.components" :key="indexC" v-bind:is="component.componentType" class="component" v-bind="component" v-on="component.customEvents" @click.native="component.click ? component.click : () => {}"/>
                            <div class="divider"></div>
                        </div>
                    </div>
                </div>
                <div class="bottom-line"></div>
            </div>
        </div>
        <div>
            <div class="row sum" v-for="(row, indexR) in rowsSum" :key="indexR" v-bind:style="[{height: heightRow + 'px'}, GetSumRowStyles(row)]">
                <div class="hover"></div>
                <div class="row-container" v-bind:style="{height: heightRow + 'px'}">
                    <div class="entry" v-for="(entry, indexE) in row.entries" :key="indexE"
                         v-bind:style="[{width: GetEntryWidth(entry), 'max-width': entry.map.collapsed || GetEntryWidth(entry).substr(-2) === 'px' ? GetEntryWidth(entry) : '', flex: entry.width && entry.width.indexOf('px') >= 0 && entry.width.indexOf('calc') === -1 ? 'none' : '1 1 auto', height: heightRow + 'px', 'margin-left': entry.map.marginleft, 'margin-right': entry.map.marginRight}, GetEntryStylesSum(entry, row.data)]" v-bind:class="[{editable: entry.OnChange}, entry.align ? entry.align : entry.map.align, {'align-right': entry.map.alignRight}]" v-tooltip="entry.tooltip ? entry.tooltip : undefined">
                        <input v-if="entry.OnChange" v-bind:value="entry.label" v-on:keyup="SendChangeSum($event, row, entry)"/>
                        <div class="label" v-if="entry.label && entry.align === 'sides' && entry.label !== false && entry.label !== true" v-for="(label, indexL) in GetLabelSplit(entry.label)" :key="indexL">{{label}}</div>
                        <div class="label" v-if="entry.align !== 'sides' && entry.label !== false && entry.label !== true">{{entry.label}}</div>
                        <div class="fas fa-check-circle" v-if="entry.label === true"></div>
                        <div class="fas fa-times" v-if="entry.label === false"></div>
                    </div>
                </div>
                <div class="bottom-line"></div>
            </div>
        </div>
    </div>
</template>

<script>
    /*
        headerMap = {};                                        //headerMap is an object where each key is a column
                                                               //each header entry is a column on the table, the rows are defined by the mapping on the header and don't need need to be processed

        hideHeader: false,                                     //if you want to hide the header

        headerMapItem = {
            key: '',                                           //can be null but used for rowSum
            label: '',                                         //what it says on the header, if null then it will use format key from camel/pascal/snake/kebab case to capitalize first letter and spaces firstName/first-name => First Name
            DisplayMap: (item) => {},                          //what it says on each row beneath it
            width: '10%'                                       //the width in %, if it's null it will automatically adjust itself to take up the remainding of the non assigned width, 2 20% elements take up 40% width so the rest will be 60% / (x - 2) width
            sort: true or (a,b) => {},                         //if sort is true it'll do a default string sort, otherwise it'll use the sort function given, if it's null you can't sort
            align: 'left/center/sides'                         //how the text on the rows under the column is aligned, the default is right,
                                                               //sides means it cuts the string into parts and aligns left on each part to avoid awkward spacing
            button: {label: 'what it says', icon: 'fas fa-plus', action: ($event, mapData) => {}}
                                                               //The button on the header to the right of the text, if you assigned a data variable to the headerMapItem it'll pass that through it,
                                                               //icon is for making the button be a font awesome icon, if you use just label it'll be a normal button
                                                               //if it's null nothing will show up
            buttons: [{label/icon/action}],                    //same thing but for the row entry
            dropdown: {},                                      //if you want a dropdown on the header here it goes
            OnChange: ($event, rowData, headerMap) => {},      //if not null it will make the label be replaced with an input on hover and call the function only when you press enter
            }

            rowItem is mapped automatically, you can override by putting these in your items

            rowItem: {
                ...,
                displayLabel: {
                    'indicatorTypeA': indicatorsASumTotal,
                    'indicatorTypeB': indicatorsBSumTotal,
                    },
                                                               //if displayLabel[map.key], use this value for the label
                buttons: {
                    'indicatorTypeA': indicatorsASumTotal,
                    'indicatorTypeB': indicatorsBSumTotal,
                    },
                                                               //if buttons[map.key], use this value for the buttons
                onChange: {
                    'indicatorTypeA': false,
                    'indicatorTypeB': EditAmount,
                    },
                                                               //if onChange[map.key], use this value for the label edits, also use false if you want none
                },

            rowSumData = [{}]                                  //each key is a key from the header
            rowSumItem = [{
                key: '',                                       //same key as header where it's supposed to go
                label: ''                                      //this is a fixed value you have to process yourself
                OnChange: ($event, rowData, headerMap) => {},  //if not null it will make the label be replaced with an input on hover and call the function only when you press enter
            }]

            example:

            let headerMap = {};
            let dataItem = data[0];

            for(var x in dataItem){
                headerMap[x] = {key: x, DisplayMap: (d) => d[x], sort: true};
            }

            let dataSum = [];

            let dataSumItem = {};
            let keyStart = Object.keys(headerMap)[0];
            dataSumItem[keyStart] = {key: keyStart, label: 'Total'};
            dataSumItem['amount'] = {key: amount, label: data.map(i => i.amount).reduce((total, current) => { return total + current})};

            let state = {
                headerMap: headerMap,
                data: data,
                dataSum: dataSum,
            }

            or if you want

            let state = this.$helpers.GetDataTableStateFromObject(dataItem, data);

            state.headerMap['id'].hidden = true;
            state.headerMap['from'].DisplayLabel = (i) => view.$helpers.getMomentFromStringTZ(i.from).format('DD.MM.YY dddd');
            state.headerMap['to'].DisplayLabel = (i) => view.$helpers.getMomentFromStringTZ(i.to).format('DD.MM.YY dddd');
            state.headerMap['amount'].OnChange = (newVal, data, map) => {
                                                                            data[map.key] = newVal;
                                                                            view.EditData(data);
                                                                        };
            state.headerMap['actions'] = {buttons:[
                                                    {icon: 'fas fa-eye', action: ($event, data) => view.PublishData($event)},
                                                    {icon: 'fas fa-pencil-alt', action: ($event, data) => view.OpenEditDataPopup($event, data)},
                                                    ]};


            state.headerMap['vacationPercentage'].componentTypeRow="inputbutton";                 //this is used to put a component into the row entry
            state.headerMap['vacationPercentage'].componentTypeColumn="inputbutton";              //this is used to put a component into the header entry
            state.headerMap['vacationPercentage'].componentStateBuilder = (rowData, entry) => {
                return {
                    state: {value: rowData.vacationPercentage / 100},                             //state is only used if the component uses state watch to initialize
                    propContainer: {text: rowData.vacationPercentage + '%'},                      //otherwise you put props in propContainer
                    customEvents: {                                                               //use these for the component $emits
                        'arrow-up' : (value) => {rowData.vacationPercentage += 10},
                        'arrow-down' : (value) => {rowData.vacationPercentage -= 10},
                        'input-change' : (value) => {rowData.vacationPercentage = value*100},
                    }
                }
            };


            state.headerMap.additionalCost.stylesEntry = {background: 'var(--contrast-1)'}; // stylesEntry is to override the default style for the entry, color here applies to the label/input, same for text-align and font-size

            state.headerMap['costDifference'].stylesEntry = (entry, rowItemData, rowIndex, datatable) =>  { ... } // can also be a function by the same name with that signature

            state.headerMap['costDifference'].stylesEntrySum = {background: 'var(--contrast-1)'}; // same but for sum rows only
            state.headerMap['costDifference'].stylesEntrySum = (entry, rowItemData, datatable) =>  { ... }


            state.headerMap['costDifference'].stylesHeader = {background: 'var(--contrast-1)'}; // same but for header only
            state.headerMap['costDifference'].stylesHeader = (entry, rowItemData, datatable) =>  { ... }

            state.headerMap.additionalCost.marginRight = '20px'; //these two add a space between this column and the next (marginRight) or previous (marginLeft)
            state.headerMap.additionalCost.marginLeft = '20px';

            state.rowHeight = '40px' //use this to define a different height for all rows, subrows and sumrows (40 is default)


            state.headerMap.paidVacationTime.collapsedIcon = 'fa-cog'; //this is how you set up collapsable icons, if you don't define .collapsedIcon it'll default to 'fa-angle-right'
            state.headerMap.paidVacationTime.collapsed = true;

     */

    import dropdownsearch from './dropdownsearch.vue'
    import Sortable from 'sortablejs'

    export default {
        name: "datatable",
        components: {
            dropdownsearch,
            Sortable
        },
        data() {
            return {
                headerMap: [],
                data: [],
                dataSum: [],
                filters: [],
                sort:{column: {label: ''}, descending: false},
                collapsedStates: {},
                hideHeader: false,
                pageCurrent: 0,
                rowsSelected: [],
                headerStyles: {},
                compact: false,
                inputFocusState: {},
                heightRow: 40,
                heightSubRow: null,
                rowStyles: {},
                sumRowStyles: {},
                coloredHeader: false,
                collapsedWidth: '25px',
                draggable: false,
                draggableId: 0,
                dragOptions: {

                },
            }
        },
        props: {
            state: Object,
            filterCheckState: Object,
            filterState: Object,
            pageState: Object,
            rowSelect: Boolean,
        },

        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal){
                    for(let x in newVal) this[x] = newVal[x];
                    let sortColumn = Object.values(this.headerMap).find(h => h.label === this.sort.column.label);
                    if(sortColumn){
                        if(sortColumn.sort !== true) this.data.sort(sortColumn.sort);
                        else this.data.sort((a, b) => (sortColumn.DisplayLabel(a) > sortColumn.DisplayLabel(b) ? -1 : 1));
                        if(!this.sort.descending) this.data.reverse();
                    }
                }
            },
            filterState: {
                immediate: true,
                handler(newVal, oldVal){
                    this.filters = newVal;
                }
            },
        },
        mounted: function() {
          let view = this;
          if (this.draggable) {
            var el = document.getElementById('draggable' + this.draggableId);
            // console.log(el);
            var sortable = Sortable.create(el, view.dragOptions);
          }
        },
        computed: {
            header(){
                let view = this;
                let entries = [];
                Object.values(this.headerMap).filter(e => typeof e.collapsed === 'boolean').forEach(e => {
                    if(e.collapsed){
                        if(!e.widthActual) e.widthActual = e.width;
                        if(e.width) e.widthActual = '';
                        e.width = view.collapsedWidth;
                        if(!e.stylesEntryActual) e.stylesEntryActual = e.stylesEntry;
                        if(!e.stylesEntryActual) e.stylesEntryActual = {};
                        e.stylesEntry = {background: 'var(--contrast-1)', opacity: '.7'};
                    } else {
                        e.stylesEntry = e.stylesEntryActual;
                    }
                    e.componentTypeColumn = 'buttonc';
                    let entry = e;
                    let icon = e.collapsed ? e.collapsedIcon ? e.collapsedIcon : 'fa-angle-right' : e.icon != undefined? '' : 'fa-angle-left';
                    let style = e.collapsed ?
                     {'font-size': '14px', width: '20px', height: '20px',top: '0', right: '0', left: '0', bottom: '0', margin: 'auto', 'position': 'absolute'} : e.icon != undefined?
                     {'visibility': 'hidden !important'} : e.align == "right" ?
                     {'font-size': '14px', width: '20px', height: '20px', top: '0', bottom: '0', left: '-8px', margin: 'auto', 'position': 'absolute', transform: 'scale(0.8)'} :
                     {'font-size': '14px', width: '20px', height: '20px', top: '0', bottom: '0', right: '0px', margin: 'auto', 'position': 'absolute', transform: 'scale(0.8)'};;
                    e.componentPropContainer = {type: 'icon-overlay', styleButton: style, icon: icon};
                    e.customEvents = {
                        click: () => {
                            if(entry.collapsed === false) {
                                entry.collapsed = true;
                                entry.width = view.collapsedWidth;
                            } else if(entry.collapsed === true) {
                                entry.collapsed = false;
                                entry.width = entry.widthActual === '' ? undefined : entry.widthActual;
                            }
                        },
                    }
                });
                for(let x in this.headerMap){
                    let entry = this.headerMap[x];
                    if(!entry.hidden) {
                        let label = entry.label;
                        if (label === undefined && entry.key) {
                            let temp = '';
                            entry.key.split('_').filter(l => l.length > 0).forEach(l => temp += l[0].toUpperCase() + l.substr(1) + ' ');
                            label = temp;
                            temp = '';
                            label.split('-').filter(l => l.length > 0).forEach(l => temp += l[0].toUpperCase() + l.substr(1) + ' ');
                            label = temp;
                            temp = '';
                            label.split(/(?=[A-Z])/).filter(l => l.length > 0).forEach(l => temp += ' ' + l);
                            label = temp;
                            label = label.replace(/\s\s+/g, ' ').trimRight();
                        }
                        let width = entry.width;
                        if (!width) {
                            let totalPercent = 0;
                            let totalPixel = 0;
                            let assignedPercent = 0;
                            let assignedPixel = 0;
                            Object.values(this.headerMap).filter(h => h.width && h.width.indexOf('%') >= 0 && !isNaN(parseInt(h.width.substr(0, h.width.length - 1)))).forEach(h => {
                                assignedPercent++;
                                totalPercent += parseInt(h.width.substr(0, h.width.length - 1));
                            });
                            Object.values(this.headerMap).filter(h => h.width && h.width.indexOf('px') >= 0 && !isNaN(parseInt(h.width.substr(0, h.width.length - 2)))).forEach(h => {
                                assignedPixel++;
                                totalPixel += parseInt(h.width.substr(0, h.width.length - 2));
                            });
                            let remaining = 100 - totalPercent;
                            width = 'calc(' + (remaining / (Object.values(this.headerMap).length - assignedPercent)) + '% - ' + (totalPixel / (Object.values(this.headerMap).length - assignedPixel)) + 'px)';
                        }
                        let entryTemp = {label: label, width: width, map: entry,
                            componentTypeColumn: entry.componentTypeColumn,
                            componentState: entry.componentState,
                        };
                        entries.push(entryTemp);
                    }
                }
                return entries;
            },
            rows(){
                let view = this;
                // console.log("rows");

                view.collapsedStates = {};
                let sortColumn = Object.values(this.headerMap).find(h => h.label === view.sort.column.label);
                if(sortColumn){
                    if(sortColumn.sort !== true) view.data.sort(sortColumn.sort);
                    else view.data.sort((a, b) => (sortColumn.DisplayLabel(a) > sortColumn.DisplayLabel(b) ? -1 : 1));
                    if(!view.sort.descending) view.data.reverse();
                } else if (view.data.length > 0 && view.data[0].hasOwnProperty('fieldOrder')) {
                  // console.log("sorting in dts");
                  // view.data.forEach(d => {
                  //   console.log(d.fieldOrder, d.newName);
                  // });
                  view.data.sort((a,b) => a.fieldOrder - b.fieldOrder);
                  // view.data.forEach(d => {
                  //   console.log(d.fieldOrder, d.newName);
                  // });
                  // console.log("view.data", view.data);
                }

                let rowsTemp = [];
                let filteredData = this.filters && this.filterCheckState ? this.data.filter(d => {
                    let passed = true;
                    for(let x in view.filterCheckState){
                        let filter = view.filterCheckState[x];
                        if(!filter.filterCheck(d)) passed = false;
                    }
                    return passed;
                }) : this.data.slice();
                if(this.pageState){
                    let start = this.pageState.pageCurrent * this.pageState.pageLength;
                    let end = (this.pageState.pageCurrent + 1) * this.pageState.pageLength;
                    if(filteredData.length > this.pageState.pageLength){
                        filteredData = filteredData.slice(start, end < filteredData.length ? end : filteredData.length);
                    }
                }
                for(let x in filteredData){
                    let rowItem = filteredData[x];
                    view.collapsedStates[x] = true;
                    let entries = [];
                    for(let y in this.headerMap) {
                        let header = this.headerMap[y];

                        if(!header.hidden) {
                            let entry = {
                                headerKey: header.key,
                                item: rowItem,
                                align: rowItem.align ? rowItem.align : header.align,
                                map: header,
                                data: rowItem,
                            };
                            if(header.componentStateBuilders) entry.components = header.componentStateBuilders.map(c => c(rowItem, entry));
                            if(entry.componentTypeRow) entry.componentStateBuilder = header.key && rowItem.componentStateBuilder && rowItem.componentStateBuilder.hasOwnProperty(header.key) ? rowItem.componentStateBuilder[header.key] : header.componentStateBuilder ? header.componentStateBuilder : null;
                            if(!entry.componentStateBuilder) entry.componentTypeRow = null;
                            if(entry.componentStateBuilder) entry.component = entry.componentStateBuilder(rowItem, entry);
                            if(header.GetEntryDropdown) {
                                entry.dropdown = header.GetEntryDropdown(entry);
                                entry.dropdown.OnToggle = (el, toggle) => {
                                    if(!toggle) {
                                        entry.dropdownActive = false;
                                        view.$forceUpdate();
                                    }
                                }
                            }
                            entries.push(entry);
                        }
                    }
                    rowsTemp.push({entries: entries, data: filteredData[x]});
                }
                let subTemp = rowsTemp.filter(r => r.data.subEntries);
                subTemp.forEach(row => {
                    row.subEntries = [];
                    for(let x in row.data.subEntries){
                        let key = x;
                        let rowItem = row.data.subEntries[key];
                        let entries = [];
                        for(let y in view.headerMap) {
                            let header = view.headerMap[y];
                            if(!header.hidden) {
                                let subEntry = {
                                    headerKey: header.key,
                                    item: rowItem,
                                    align: rowItem.align ? rowItem.align : header.align,
                                    map: header,
                                    data: rowItem
                                };
                                if(row.data.subEntries[key].componentOverride){
                                    subEntry.components = row.data.subEntries[key].componentStateBuilders.map(c => c(rowItem, subEntry));
                                } else {
                                    subEntry.components = subEntry.componentStateBuilders.map(c => c(rowItem, subEntry));
                                }

                                entries.push(subEntry);
                                if(row.data.subEntries[key].componentOverride) break;
                            }
                        }
                        row.subEntries.push({entries: entries, data: rowItem});
                    }
                });
                // console.log("rowsTemp", rowsTemp);
                return rowsTemp;
            },
            rowsSum(){
                let view = this;

                let rowsTemp = [];

                for(let x in this.dataSum){
                    let rowItem = this.dataSum[x];
                    let entries = [];
                    for(let y in this.headerMap) {
                        let map = this.headerMap[y];
                        if(!map.hidden) {
                            let label = '';
                            if (map.key && rowItem[map.key]) {
                                label = rowItem[map.key].label;
                            }
                            let align = '';
                            if (map.key && rowItem[map.key]) {
                                align = rowItem[map.key].align;
                            }
                            let OnChange = null;
                            if (map.key && rowItem[map.key]) {
                                OnChange = rowItem[map.key].OnChange;
                            }
                            let tooltip = undefined;
                            if (map.key && rowItem[map.key]) {
                                tooltip = rowItem[map.key].tooltip;
                            }
                            entries.push({label: label, map: map, item: rowItem, data: rowItem, align: align, OnChange: OnChange, tooltip: tooltip});
                        }
                    }
                    rowsTemp.push({entries: entries, data: rowItem});
                }
                return rowsTemp;
            }
        },
        methods:{
            ToggleCollapsedState(entry){
                let view = this;
                if(entry.collapsed === false) {
                    entry.collapsed = true;
                    entry.width = view.collapsedWidth;
                } else if(entry.collapsed === true) {
                    entry.collapsed = false;
                    entry.width = entry.widthActual === '' ? undefined : entry.widthActual;
                }
            },
            GetEntryWidth(entry){
                let map = entry.map;
                let width = map.width;
                if (!width) {
                    let totalPercent = 0;
                    let totalPixel = 0;
                    let assignedPercent = 0;
                    let assignedPixel = 0;
                    Object.values(this.headerMap).filter(h => h.width && h.width.indexOf('%') >= 0 && !isNaN(parseInt(h.width.substr(0, h.width.length - 1)))).forEach(h => {
                        assignedPercent++;
                        totalPercent += parseInt(h.width.substr(0, h.width.length - 1));
                    });
                    Object.values(this.headerMap).filter(h => h.width && h.width.indexOf('px') >= 0 && !isNaN(parseInt(h.width.substr(0, h.width.length - 2)))).forEach(h => {
                        assignedPixel++;
                        totalPixel += parseInt(h.width.substr(0, h.width.length - 2));
                    });
                    let remaining = 100 - totalPercent;
                    width = 'calc(' + (remaining / (Object.values(this.headerMap).length - assignedPercent)) + '% - ' + (totalPixel / (Object.values(this.headerMap).length - assignedPixel)) + 'px)';
                }
                return width;
            },
            GetTooltip(columnIndex){
                let view = this;
                return () => {return view.header[columnIndex].map.tooltip};
            },
            GetHeaderStyles(column){
                if(Object.prototype.toString.call(column.map.stylesHeader) == '[object Function]') {
                    return column.map.stylesHeader(column.map, column, this);
                }
                return column.map.stylesHeader;
            },
            GetRowStyles(row){
                let style = this.collapsedStates[this.rows.indexOf(row)] ? this.rowStylesCollapsed : this.rowStyles;
                if(Object.prototype.toString.call(style) == '[object Function]') {
                    let index = this.rows.indexOf(row);
                    if(index === -1) {
                        let rowContaining = this.rows.find(r => r.subEntries && r.subEntries.find(s => s === row));
                        if(rowContaining) index = rowContaining.subEntries.indexOf(row);
                    }
                    return style(row.data, row, index, this);
                }
                return style;
            },
            GetSumRowStyles(row){
                let style =  this.sumRowStyles;
                if(Object.prototype.toString.call(style) == '[object Function]') {
                    let index = this.rows.indexOf(row);
                    if(index === -1) {
                        let rowContaining = this.rows.find(r => r.subEntries && r.subEntries.find(s => s === row));
                        if(rowContaining) index = rowContaining.subEntries.indexOf(row);
                    }
                    return style(row.data, row, index, this);
                }
                return style;
            },
            GetEntryStyles(entry, rowItemData, row){
                if(Object.prototype.toString.call(entry.map.stylesEntry) == '[object Function]') {
                    let index = this.rows.indexOf(row);
                    if(index === -1) {
                        let rowContaining = this.rows.find(r => r.subEntries && r.subEntries.find(s => s === row));
                        if(rowContaining) index = rowContaining.subEntries.indexOf(row);
                    }
                    return entry.map.stylesEntry(entry, rowItemData, index, this);
                }
                return entry.map.stylesEntry;
            },
            GetEntryStylesSum(entry, rowItemData){
                if(Object.prototype.toString.call(entry.map.stylesEntrySum) == '[object Function]') {
                    return entry.map.stylesEntrySum(entry, rowItemData, this);
                }
                return entry.map.stylesEntrySum;
            },
            CheckButtonVisible(row, button){
                if(!button.Show) return true;
                return button.Show(row.data);
            },
            RowSelect(row){
                if(!this.rowSelect) return;
                if(row.Select) row.Select(row.data);
                this.$emit('row-click', row.data);
                if(this.rowsSelected.find(r => r === row)) {
                    this.$emit('row-unselect', row.data);
                    this.rowsSelected = this.rowsSelected.filter(r => r !== row);
                } else {
                    this.rowsSelected.push(row);
                    this.$emit('row-select', row.data);
                }
                this.$emit('rows-selected', this.rowsSelected.map(r => { return r.data }).slice());
            },
            ToggleDropdown($event, entry){
                entry.dropdownActive = true;
                let view = this;
                requestAnimationFrame(function(){
                let dropdown = view.$children.find(c => c.state === entry.dropdown);
                    if(dropdown) {
                            dropdown.toggled = true;
                    }
                });
                this.$forceUpdate();
            },
            SendChangeDropdown($event, row, entry){

            },
            BoolChangeEntry($event, row, entry){
                if(entry.OnChange) {
                    entry.OnChange(!entry.label, row.data, entry.headerKey);
                }
            },
            ChangeEntry(newVal, row, entry){
                if(entry.OnChange) {
                    entry.OnChange(newVal, row.data, entry.headerKey);
                }
                this.$forceUpdate();
            },
            SendChangeEntry($event, row, entry){
                let keyName = $event.key;

                if(entry.OnChange && (keyName === 'Enter')) {
                    entry.OnChange($event.currentTarget.value, row.data, entry.headerKey);
                }else if(keyName === 'Escape'){
                    $event.target.blur();
                }
            },
            SendChangeSum($event, row, sum){
                let keyName = $event.key;
                if($event.currentTarget.value.toString() !== sum.label.toString() && keyName === 'Enter') {
                    sum.OnChange($event.currentTarget.value, row.data, sum);
                }
            },
            GetContrastingColor(hex, lightcolor = 'white', darkcolor = '#444444', contrastLimit = 80) {
                return this.$helpers.GetContrastingColor(hex, lightcolor, darkcolor, contrastLimit);
            },
            InputFocusEvent($event, focusIndex, focused){
                this.inputFocusState[focusIndex] = focused;
                if(focused){
                    // select input text on focus
                    $event.target.select();
                }
                this.$forceUpdate();
            },
            CheckFocusState($event, focusIndex){
                let keyName = $event.key;
                if(keyName === 'Enter'){
                    this.inputFocusState[focusIndex] = false;
                    $event.target.blur();
                }
                this.$forceUpdate();
            },
            GetLabelSplit(string){
                if(!string) return '';
                string = string.toString();
                return string.split(' ');
            },
            Sort(column){
                if(!column.map.sort) return;
                if(this.sort.column.label === column.label) {
                    this.sort.descending = !this.sort.descending;
                } else {
                    this.sort.column = column;
                    this.sort.descending = true;
                }
                if(column.map.sort !== true) this.data.sort(column.map.sort);
                else this.data.sort((a, b) => (column.map.DisplayLabel(a) > column.map.DisplayLabel(b) ? -1 : 1));
                if(!this.sort.descending) this.data.reverse();
                this.$forceUpdate();
            },
            CollapseRow(index){
                if(!this.collapsedStates[index]) this.collapsedStates[index] = false;
                this.collapsedStates[index] = !this.collapsedStates[index];
                this.$forceUpdate();
            },
        }
    }
</script>

<style scoped>
    .data-table{
        display: block;
        clear: both;
        padding-bottom: 30px;
        /*overflow: hidden;*/
    }

    .header{
        padding: 10px 0 11px;
        display: flex;
        justify-content: space-evenly;
        box-shadow: inset 0 2px 0 -1px var(--contrast-3), inset 0 -3px 0 -1px var(--contrast-4);
    }

    .header .entry > .icon.fas{
        position: absolute;
        font-size: 20px;
        left: 0px;
        top: -6px;
        bottom: 0;
        height: 15px;
        color: var(--contrast-4);
        cursor: pointer;
    }

    .header .entry > .icon.far{
        position: absolute;
        font-size: 20px;
        left: 0px;
        top: -6px;
        bottom: 0;

        height: 15px;
        color: var(--contrast-4);
        cursor: pointer;
    }

    .header .entry > .icon.fas.cyan {
        color: #1e9e9a !important;
    }

    .header .entry > .icon.fas.green {
        color: #99af65 !important;
    }

    .header .entry{
        display: flex;
        width: auto;
        flex: 1 1 auto;
        justify-content: center;
        box-shadow: inset -2px 0 0 -1px var(--contrast-3);
        height: 30px;
        position: relative;
        align-items: center;
    }

    .header .entry.left{
        justify-content: flex-start;
    }

    .header .entry.right{
        justify-content: flex-end;
    }

    .header .entry > .label{
        font-family: DistrictProBold;
        font-size: 16px;
        margin: 0 10px;
    }

    .header .entry.no-border ,.header .entry:last-child{
        box-shadow: none;
    }

    .entry > .label{
    }

    .entry.editing .label{
        opacity: 0;
    }
    .entry.editing{
        padding: 0 !important;
    }
    .entry.editing .input-component{
        opacity: 1;
        position: absolute;
        background: var(--bg);
        border-radius: 100px;
        /*margin-left: 16px;*/
        height: 25px;
        padding: 5px 10px;
        width: 100%;
    }

    .row{
        display: block;
        width: 100%;
        position: relative;
        height: 40px;
        margin: 0;
    }


    .row .row-container{
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        border-radius: 100px;
        position: relative;
    }


    .row.collapsed .row-container.sub{
        display: none;
    }



    .row .row-container.sub{
        width: 100%;
        position: relative;
        height: 40px;
        display: block;
    }

    .flex-divider{
        flex-basis: 100%;
        width: 0px;
        height: 0px;
        margin: 0;
        overflow: hidden;
    }

    .row .bottom-line{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        box-shadow: inset 0 -2px 0 -1px var(--contrast-3);
    }


    .row .row-container > .entry{
        display: flex;
        width: auto;
        flex: 1 1 auto;
        justify-content: flex-end;
        height: 40px;
        position: relative;
        align-items: center;
        padding: 0 10px;
    }

    .row .entry {
        color: var(--contrast-5);
        font-size: 13px;
    }
    .row .entry.collapsed > *{
        display: none !important;
    }

    .row .entry .label{
        color: inherit;
        text-align: inherit;
    }

    .flex-divider{
        flex-basis: 100%;
        width: 0px;
        height: 0px;
        margin: 0;
        overflow: hidden;
    }

    .entry > .button{
        left: initial;
        right: initial;
        top: 0;
        bottom: 0;
        margin: 0 5px;
        display: inline-flex;
        align-items: center;
        height: 20px;

    }

    .entry > .button.pill{
        border-radius: 100px;
        height: 25px;
        padding: 0px 17px;
        background: var(--bg);
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: center;
    }

    .entry > .button.pill:hover{
        background: var(--contrast-4);
    }


    .entry > .button.fas{
        border-radius: 100px;
        height: 25px;
        width: 25px;
        background: var(--bg);
        justify-content: center;
        font-size: 12px;
    }


    .entry > .button.fa-plus{
        font-size: 14px;
    }

    .entry > .button.fas:hover{
        background: var(--contrast-4);
        color: var(--bg);
    }

    .entry > .button.fas *{
        color: var(--contrast-4);
    }

    .entry.editable .label{
        display: none !important;
    }

    .entry.editable:hover{
        padding: 0;
    }

    .entry.editable .input-component{
        opacity: 1;
        position: absolute;
        background: var(--bg);
        border-radius: 100px;
        height: 30px;
        padding: 5px 10px;
        width: 100%;
    }


    .entry.editable:hover .input-component{
        opacity: 1;
    }

    /* field currently editing should stay focused */
    .entry.editing .label{
        opacity: 0;
    }
    .entry.editing{
        padding: 0 !important;
    }
    .entry.editing .input-component{
        opacity: 1;
        position: absolute;
        background: var(--bg);
        border-radius: 100px;
        margin-left: 16px;
        height: 25px;
        padding: 5px 10px;
        width: 100%;
    }

    .entry .dropdown-search{
        position: absolute;
        left: 5px;
        top: 5px;
        bottom: 0;
        margin: auto;
        width: calc(100% - 10px);
    }

    .row:hover .entry .dropdown-search{

    }





    .row  .row-container > .entry .label, .row .row-container > .entry .input-component{
        font-size: inherit;
        font-family: DistrictProLight;
        text-align: inherit;
        font-weight: 200;
        display: inline-block;
        margin: initial;
        /*height: calc(100% - 10px);*/
        width: 100%;
    }

    .row .row-container > .entry .input-component {
        position: absolute;
        background: none;
        height: 25px;
        padding: 5px 10px;
        width: 100%;
        left: 0;
     }

    .row .entry.left{
        justify-content: flex-start;
    }

    .row .entry.left{
        text-align: left;
    }

    .row .entry.right{
        justify-content: flex-end;
    }

    .row .entry.right{
        text-align: right;
    }

    .row .entry.center{
        justify-content: center;
        text-align: center;
    }
    .row .entry.sides{
        justify-content: space-between;
    }
    .row .entry.sides{
        width: 50%;
        text-align: left;
    }

    .header .entry > .fas{
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 15px;
        color: var(--contrast-3);
        cursor: default;
    }

    .header .entry > .far{
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 15px;
        color: var(--contrast-3);
        cursor: default;
    }

    .header .entry.editable > .fas{
        cursor: pointer;
    }



    .header .entry.sortable{
        cursor: pointer;
    }

    .header .entry > .label{
        pointer-events: none;
    }

    .header .dropdown-search{
        position: absolute;
        left: 5px;
        top: 3px;
        bottom: 0;
        margin: auto;
        width: 100px;
    }

    .header .button{
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 15px;
    }


    .header .entry > .fa-plus{
        left: 0;
        right: 0;
        font-size: 20px;
        pointer-events: initial;
        height: 40px;
        width: 40px;
        border-radius: 100px;
        -webkit-transition: .2s;
        transition: .2s;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .header .entry > .fa-pencil-alt{
        transform: scale(.8);
        opacity: 0;
        transition: .2s;
    }

    .header .entry:hover > .fa-pencil-alt{
        opacity: 1;
    }


    .header .entry > .fa-plus:hover{
    }



    .row-container > .hover{
        border-radius: 100px;
        left: -20px;
        top: 0;
        width: calc(100% + 40px);
        background: var(--contrast-3);
        position: absolute;
        z-index: -1;
        display: none;
        pointer-events: none;
        height: 40px;
    }
    .row-container.selected > .hover{
        background: var(--contrast-1);
        display: inline-block;
        opacity: .8;
    }
    .row-container:hover > .hover{
        background: var(--contrast-3);
        display: inline-block;
        opacity: 1;
    }

    .row.sum .entry .label{
        font-weight: 800;
    }

    .row .fa-angle-down,.row .fa-angle-up{
        position: absolute;
        right: 5px;
        top: 0;
        bottom: 0;
        margin: auto;
        color: var(--contrast-4);
        background: white;
        height: 20px;
        width: 20px;
        border-radius: 200px;
        align-content: center;
        display: flex;
        justify-content: center;
    }

    .row .fa-angle-down:before,.row .fa-angle-up:before{
        height: 16px;
        display: flex;
        align-self: center;
    }

    .row .click-container{
        height: 100%;
        width: 100%;
        color: var(--contrast-3);
        top: 0;
        line-height: 28px;
        left: 0;
        text-align: right;
        padding: 7px;
        position: absolute;
    }

    .row:hover .click-container{
        color: white;
    }


    .entry:hover .click-container{
        color: #404040;
    }

    .entry.edit-disabled .click-container{
        opacity: .3;
        pointer-events: none !important;
    }

    .header.hidden{
        display: none;
    }

    .align-right{
        margin-right: 0;
    }
    .align-left{
        margin-left: 0;
    }
    .header .entry.label-small > .label{
        font-size: 15px;
    }
    .header .entry.label-no-break > .label{
        white-space: nowrap;
    }
    .header .entry.collapsed > .label{
        display: none !important;
    }


    .entry .divider{
        display: none;
    }

    .entry.divider{
        margin: initial;
    }

    .entry.divider .divider{
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 10px);
        background: var(--contrast-3);
        display: inline-block;
    }

    .row:hover .entry.divider .divider{
        background: var(--contrast-1);
    }

    .entry:last-child .divider{
        display: none;
    }

    .data-table.compact .header{
        padding: 0;
        justify-content: left;
        box-shadow: none;
        border-bottom: 1px solid var(--bg);
    }

    .data-table.compact .row .row-container{
        justify-content: left;
    }

    .data-table.compact .header > .entry{
        box-shadow: none;
        padding: 0;
        margin: 0;
    }
    .data-table.compact .header > .entry, .data-table.compact .header{
        height: 30px;
    }
    .data-table.compact .row,.data-table.compact .row-container, .data-table.compact .row-container .entry{
        height: 40px;
        padding: 0;
    }
    .data-table.compact .row .entry,.data-table.compact .header > .entry{
        border-right: 1px solid  var(--bg);
    }
    .data-table.compact .bottom-line{
        box-shadow: inset 0 -2px 0 -1px  var(--bg);
    }

    .data-table .collapsed{
        /*background: var(--contrast-1) !important;*/
    }

    .sortable-chosen {
      box-shadow: 0px 0px 1px 3px var(--ml);
    }

</style>
